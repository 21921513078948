import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { TokenService } from '../../features/auth/services/token.service';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { catchError, throwError } from 'rxjs';
import { SnackbarService } from '../services/snackbar.service';

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  const tokenService = inject(TokenService);
  const router = inject(Router);
  const snackBarService = inject(SnackbarService);
  if (isNoNeedToken(req.url)) {
    return next(req).pipe(
      catchError((err: HttpErrorResponse) => {
        const error = err.error.message || err.statusText;
        snackBarService.show(error);
        return throwError(() => error);
      })
    );
  } else {
    tokenService.isAuthentication.subscribe({
        next: (value) => {
          if (value) {
            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${tokenService.getAccessToken()}`
              }
            });
          }
        }
    });

    return next(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          tokenService.removeToken();
          router.navigateByUrl('/');
        }
        const error = err.error.message || err.statusText;
        snackBarService.show(error);
        return throwError(() => error);
      })
    );
  }
};

function isNoNeedToken (url: string) {
  return url === `${environment.apiURL}/api/login_check`
    || url === `${environment.apiURL}/api/token/refresh`
    || url.includes('https://api-adresse.data.gouv.fr')
    || url.includes('https://homologation-webpayment.payline.com/webpayment/getToken')
    || url.includes('https://webpayment.payline.com/webpayment/getToken');
}
