import { Routes } from '@angular/router';
import { URLS } from './shared/constants/urls';

export const routes: Routes = [
  {
    path: '',
    redirectTo: URLS.AUTH,
    pathMatch: 'full'
  },
  {
    path: URLS.AUTH,
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: URLS.REGISTRATION,
    loadChildren: () => import('./features/registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: URLS.MANAGER,
    data: {breadcrumb: `Accueil`},
    loadChildren: () => import('./features/manager/manager.module').then(m => m.ManagerModule)
  },
  {
    path: URLS.ARTISAN,
    data: {breadcrumb: `Accueil`},
    loadChildren: () => import('./features/artisan/artisan.module').then(m => m.ArtisanModule)
  },
  {
    path: URLS.THIRD_PARTY_BILLING,
    loadChildren: () => import('./features/third-party-billing/third-party-billing.module').then(m => m.ThirdPartyBillingModule)
  },
  {
    path: URLS.CONFIRM,
    loadChildren: () => import('./features/confirm/confirm.module').then(m => m.ConfirmModule)
  },
  {
    path: URLS.DELETE_PROFILE,
    loadChildren: () => import('./features/delete-profile/delete-profile.module').then(m => m.DeleteProfileModule)
  },
  {
    path: URLS.RESET_PASSWORD + '/:token',
    loadChildren: () => import('./features/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  }
];
